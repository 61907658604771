export function handleAutoSubmitForm() {
  const autoSubmitFormEl = document.querySelector("form[data-auto-submit='true']")
  if (autoSubmitFormEl) {
    autoSubmitFormEl.submit()
  }
}

let defaultSeparator = undefined
export function changeDecimalSeparator(value, separator) {
  if (!separator) {
    if (!defaultSeparator) {
      const separatorMetaTag = document.querySelector('meta[name="gs-decimal-separator"]')
      if (separatorMetaTag) {
        defaultSeparator = separatorMetaTag.content
      }
    }
    separator = defaultSeparator
  }

  if (!separator) {
    return value
  }

  if (separator == ",") {
    return value.replace(".", separator)
  } else {
    return value.replace(",", ".")
  }
  return value
}