import Rails from "@rails/ujs"

export function handleCookieConsent(cookieConsentEl, initFn, finishFn) {
  if (!cookieConsentEl) {
    return
  }

  if (initFn) {
    initFn(cookieConsentEl)
  }

  const dismissBtnEl = cookieConsentEl.querySelector("[data-action='dismiss-cookie-consent']")
  if (dismissBtnEl) {
    dismissBtnEl.addEventListener("click", function(e) {
      e.preventDefault()

      const url = dismissBtnEl.dataset.url
      if (url && url.length > 0) {
        Rails.ajax({
          type: "POST",
          url: url,
        })
      }

      cookieConsentEl.classList.add("cookie-consent--hiding")

      if (finishFn) {
        finishFn(cookieConsentEl)
      }
    })
  }

  cookieConsentEl.addEventListener("transitionend", function (e) {
    if (e.target == cookieConsentEl) {
      cookieConsentEl.remove()
    }
  }, true)
}
